form {
  padding: 100px;
  box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.06), 0 1px 5px 0 rgba(0, 0, 0, 0.07);
  background: #fff;
}

.dates {
  display: flex;
  justify-content: space-between;
}

.date label {
  color: hsl(280, 15%, 30%);
  display: block;
  padding-bottom: 15px;
}

input[type="submit"] {
  display: block;
  text-align: center;
  margin: 0 auto;
  margin-top: 50px;
  width: 100%;
  padding: 20px;
  font-size: 1.5em;
  background: hsl(280, 80%, 40%);
  color: #fff;
  border: none;
  outline: none;
  cursor: pointer;
}

input[type="submit"]:hover {
  background: hsl(280, 80%, 50%);
}

.error {
  padding-top: 30px;
  color: hsl(0, 80%, 50%);
}

::-webkit-datetime-edit {
  padding: 20px;
  font-size: 1.5em;
  color: hsl(280, 15%, 30%);
}

::-webkit-inner-spin-button {
  display: none;
}

::-webkit-calendar-picker-indicator {
  display: none;
}

::-webkit-datetime-edit-text {
  padding: 0 2px;
}

input:focus {
  outline: none;
  border: 2px solid hsl(280, 10%, 60%);
}
